import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import Template from '../components/Template'
import { CustomLoader, ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';
import { CURRENCY, ImagesUrl, ServerUrl, config } from '../components/Includes';

import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { FormatNumber, compare } from '../components/GlobalFunction';
import Swal from 'sweetalert2';
import { DOTS, usePaginated } from '../components/usePagination';
const EditCollection =()=> {

	let params = useParams()

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([] as any)
	const [property, setProperty] = useState([] as any)
    const [errors, setErrors] = useState({
        layout_type:'',
        title:'',
		collection_type:''
    });
	const [product, setProduct] = useState([] as any)
   


	const fetchProperty  = async()=>{
		
				 const fd = new FormData();
				
				 let url = ServerUrl+'/property/view'
			   await  axios.post(url, fd, config).then((result:any)=>{
	 
				 if(Array.isArray(result.data.data) && result.data.data.length!==0){
				   setProperty(result.data.data)
				   }
				 })
				 .catch((error)=>{
					 
			  ErrorModal('oops', error.message)
				 })
				 } 

       const fetchContent = async()=>{
      LoadingModal()
          let url = ServerUrl+'/collection/edit/'+params.code
        await  axios.get(url,  config).then((result:any)=>{
  
          if(result.data.data&&result.data.data.length!==0){

			try{

				let item = JSON.parse(result.data.data.item_list)
				setProduct(item)
				setContent(result.data.data)

			}catch(e){

			}
          
          }else{
            window.open('/collections', '_self')
          }
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          }).finally(()=>{
           closeModal()
         })
          } 


           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }

         const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
            let {name, value} = event.target;	
			if(name==='is_active'){
				value = value==='false'?'true':'false'
			  } 
			setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }


		 const handleSubmit =(event: FormEvent<HTMLButtonElement>, ref:string)=>{
			event.preventDefault();
		
			let error = {...errors}; 
			let formIsValid = true;
		
		let msg ='This field is required';
		
	
		if(!content.title){
			error.title=msg;
			formIsValid = false;
		} 
	
	  if(!content.collection_type){
			error.collection_type =msg;
			formIsValid = false;
		} 
		
		if(!content.layout_type){
			error.layout_type =msg;
			formIsValid = false;
		} 
		if(!formIsValid){
			setErrors(error) 
			}
			
			
			if(formIsValid) {
			
				LoadingModal()
				const fd = new FormData();
				   Object.entries(content).forEach(([key, value]) => {
						   fd.append(key, String(value));
					   });
					  
	
					   
					   let url = ServerUrl+'/collection/update/'+params.code;
					   axios.post(url, fd, config)
					   .then(response =>{
						 if(response.data.status === 200){
						  SuccessModal(response.data.message)
						window.open(ref, '_self');
	
								   } else{
									 ErrorModal('', response.data.message)
								   }   
							   })
							   .catch((error)=>{
								ErrorModal('oops', error.message)
							   }) 
			}
	
	}
	

	const handleRemoveProduct =(code:string)=>{
		
		const itemList = product.filter((list:any)=>list.code!==code)
		UpdateProductsCollection(itemList)
		
	}



	  const handleSelectAll =(list:[])=>{
      
		let is_check = list.every((element:any) => {
		 return element.is_check === 'true'
		});
		var i = 0;
			list.forEach((element:any) => {
			  if(element.is_check === 'true')
			 i++
			});
		
		   }
  
  const handleCheck =(code:string)=>{
	const newContent = property.map((item:any)=>{
	   
	if(item.code === code){
	return {...item, 
		is_check:item.is_check==='false'?'true':'false',
	}
	};
	return item;
	})
	setProperty(newContent)
	handleSelectAll(newContent)
	}
	


	  const TableData =()=>{

		const PaginationWrapper =(props:any)=>{
		
			const handleRowsPerPage = useCallback(
				(e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
				[props.currentPage, props.onChangeRowsPerPage],
			);
			const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
			[props.currentPage, props.onChangeRowsPerPage],
			)
		   
			function getNumberOfPages(rowCount:number, rowsPerPage:number) {
				return Math.ceil(rowCount / rowsPerPage);
			  }
			const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
			const lastIndex = props.currentPage * props.rowsPerPage;
			const firstIndex = lastIndex - props.rowsPerPage + 1;
		
		
				const range =
				props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
		
				`Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
		
			
		   
			const Pagination = () => {
			   
				const pageSize = props.rowsPerPage;
				const totalCount = content.length
				const siblingCount = 1
				const currentPage = props.currentPage
				
				const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
				
				if (currentPage === 0 ) {
				  return null;
				}
				
				let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
				return (
				<nav className="" aria-label="Page navigation sample ">
				  <ul  className="pagination pagination-sm"  >
					<li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
					 <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
					</li>
					{paginationRange&&paginationRange.map((pageNumber, index) => {
					  if (pageNumber === DOTS) {
						return <li className="pagination-item dots" key={index}>
						 <a className="page-link pointer"  >&#8230;</a>
						</li>
					  }
					  return (
						<li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
						  <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
						  
						</li>
					  );
					})}
				
				<li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
				<a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
					</li>
				
				  </ul> </nav>
				);
				
					}
		
				  
		
				  
		
		 return <div className="row mt-3">
							
		<div className="col-md-5 ">
		
		
		   Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
				<option value="10"> 10</option>
				<option value="20"> 20</option>
				<option value="30"> 30</option>
				<option value="40"> 40</option>
				<option value="50"> 50</option>
				<option value={content.length}> All</option>
			</select> &nbsp;&nbsp;
			 {range}  </div>
		
		  
		<div className="col-md-7  justify-content-end d-flex">
		   <Pagination  />
			</div>
			
		</div>}
		
		
		
		
		const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 
		
		return <div className="chk-option">
		<div className="checkbox-fade fade-in-primary">
			<label className="check-task">
		
			 <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} /> 
						
			<span className="cr">
				<i className="cr-icon fa fa-check txt-default"></i>
			</span>
			</label>
		</div>
		</div>}
		
		
		
					const columns = [
		
						{
							name: 'Select',
							cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
							width:'150px'
						},
						 {
							name: 'Picture',
							cell: (row:any) => 
							<img  className="img-fluid img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${row.cover_image}`}  />
					   , 
							sortable: true,
							reorder:true,
							width:'100px'
						}, 
						{
						  name: 'Headline',
						  cell: (row:any) => row.headline, 
						  sortable: true,
						  reorder:true,
						  wrap:true,
		
					  },
						{
							name: `Price(${CURRENCY})`,
							selector: (row:any) => FormatNumber(row.price),
							sortable: true,
							reorder:true,
							width:'150px'
						},
						
						
						{
							name: 'Publish',
							selector: (row:any) => row.publish,
							sortable: true,
							reorder:true,
							wrap:true,
						},
					];
					
				
				
				
					return <>
					
		
					<DataTable
					columns={columns}
					data={property}
					highlightOnHover
					pagination
					paginationComponent={PaginationWrapper}
					selectableRowsHighlight
					striped
					responsive
					fixedHeader
					progressPending={loading}
					progressComponent={<CustomLoader />}
				/>
				</>
				}

				const getItemName =(code:string, field:string)=>{
					const result = property&&property.filter((item:any)=>item.code===code)
					const answer = result.length!==0?result[0][field]:''
					return String(answer)
				}

				const getProductName =()=>{
					let result:any = []
					product&&product.map((item:any, id:number)=>
					
					
					result.push({
						code:item.code, 
						key:id, 
						publish:getItemName(item.code, 'publish'), 
						headline:getItemName(item.code, 'headline'),
						cover_image:getItemName(item.code, 'cover_image'),
						price:getItemName(item.code, 'price')
					}))
					return result
				  }

				  

				  const handleAddNewProduct =(event:any)=>{
					event.preventDefault()
					
					let records = []
					
					 const newProducts = [...property]
						for (var i in newProducts){
							if(newProducts[i].is_check==='true'){
								records.push({
									code:newProducts[i].code
								})
							}
						}


						//merger old prouct list with selected product
						const currentList = product.concat(records)
						//remove the duplicate item
						const itemList =	currentList.map((e:any)=>e['code'])
						.map((e:any,i:any,final:any)=>final.indexOf(e)===i&&i)
						.filter((e:any)=>currentList[e])
						.map((e:any)=>currentList[e])
		
						if(records.length===0){
							ErrorModal('oops', 'Please select at least one product to add')
						}else{
							UpdateProductsCollection(itemList);
						}
						let close =   document.getElementById('closeIt')
						close?.click();
				}



				const UpdateProductsCollection =(item_list:any)=>{
          
					LoadingModal()
				var productList = JSON.stringify(item_list, null, 2)
				
					const fd = new FormData();

					fd.append('code', String(params.code))
					fd.append('item_list', productList)
				let url = ServerUrl+'/collection/update_item'
					  axios.post(url, fd, config)
					  .then(response =>{
					   if(response.data.status ===200){
						SuccessModal(response.data.message)
							  } else{
						  
						  ErrorModal('oops', JSON.stringify(response.data))

							  }   
					  })
					  .catch((error)=>{
						ErrorModal('oops', error.message)
					  }).finally(()=>{
						  fetchContent()
					  })  
			}


useEffect(()=>{
	fetchContent();
	fetchProperty()
}, [])
		 
  return (<Template>

<main id="main" className="main">
<div className="pagetitle">
  <h1>Update Collection</h1>

  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item"><a href="/collections">Collection</a></li>
      <li className="breadcrumb-item active">Update Collection</li>
    </ol>

    <div>
    <button className='btn btn-success ' onClick={(e)=>handleSubmit(e, '/collections')}> <i className="bi bi-check2-circle"></i> Save Collection</button>&nbsp;

    <button className='btn btn-warning ' onClick={(e)=>handleSubmit(e, '/collection/edit/'+content.code)}> <i className='bi bi-pencil'></i>Save & Edit</button>
    </div>
  </nav>
</div>





<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Update Collection</h5>

<div className="card-body"> 






<div className="modal fade" id="addproperty" tabIndex={-1} aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-fullscreen">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add New Property</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">


				<TableData />

                    </div>
                    <div className="modal-footer">
                      <button type="button" id='closeIt' className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					  <button type="button" onClick={handleAddNewProduct}  className="btn btn-success">Add Selected</button>
                    </div>
                  </div>
                </div>
              </div>



<div className="row">
<div className="col-sm-12">

						<section className="card-block">
                        
					<div className="form-group row mb-3 mt-3">

                    <label className="col-sm-3 col-form-label">Title</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="title" value={content.title} className='form-control '/>
						<span className="text-danger"> *</span>
							</div>
                            <i>Collection title as you want it to appear on home page</i>

							<span className="error">{errors.title}</span>
							</div>
					</div>
					
					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Collection Type</label>

					
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="collection_type" disabled value={content.collection_type} className="form-control" onChange={handleChangeSelect}>
							<option value="property">Property</option>
							<option value="category">Category</option>
							<option value="review">Customer Review</option>
							<option value="banner">Banner</option>
							<option value="video">Video</option>
							</select>
							</div>
							</div>
					</div>

{content.collection_type==='category'?
					<div className="form-group row mb-3">
                           <label className="col-sm-3 col-form-label">Layout Type</label>

					
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Brand Layout 1">Brand Layout 1</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:
					content.collection_type==='video'?


					<div className="form-group row mb-3">
		    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Video Layout 1">Video Layout 1</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>: content.collection_type==='property'?



					<div className="form-group row mb-3">
			    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Product Layout 1">Product Layout 1</option>
							<option value="Product Layout 2">Product Layout 2</option>
							<option value="Product Layout 3">Product Layout 3</option>
							<option value="Product Layout 4">Product Layout 4</option>
							<option value="Product Layout 5">Product Layout 5</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:content.collection_type==='review'?

					<div className="form-group row mb-3">
		    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Customer Review Layout 1">Customer Review Layout 1</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:content.collection_type==='banner'?
					<div className="form-group row mb-3">
					    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Layout 1">Layout 1</option>
							<option value="Layout 2">Layout 2</option>
							<option value="Layout 3">Layout 3</option>
							</select>
						<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>
					:
					<div className="form-group row mb-3">
					    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Category Layout 1">Category Layout 1</option>
							</select>
						<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>}


					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Display Order</label>

				
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}   name="display_order" value={content.display_order} className='form-control'/>
							
							</div>
							</div>
					</div>



					<div className="form-group row">
                    <label className="col-sm-3 col-form-label">Is Active</label>
			
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.is_active} name="is_active" checked={content.is_active==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	</section></div></div></div>
       
            </div>
            
            </div>

         
          
            </div>
            </section>


            <div className="accordion mb-3" id="accordionPicture">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingPicture">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePicture" aria-expanded="true" aria-controls="collapsePicture">
                    Properties
                    </button>
                  </h2>
                  <div id="collapsePicture" className="accordion-collapse collapse show" aria-labelledby="headingPicture" data-bs-parent="#accordionPicture" >
                    <div className="accordion-body">


                   

					<div className=" col-md-8 mb-3">
  
  <button type="button" className="btn btn-outline-success " data-bs-toggle="modal" data-bs-target="#addproperty"><i className="bi bi-plus"></i> Add Property</button>
 
				 </div>

<table className="table table-striped mb-3">
                <thead>
                  <tr>

				  <th scope="col">Cover</th>
                    <th scope="col">Headline</th>

                    <th scope="col">Price</th>
                    <th scope="col">View</th>
                    <th scope="col">x</th>
                  </tr>
                </thead>
                <tbody>
                {getProductName().sort(compare).map((list:any, index:number)=>
                  <tr key={index}>

<td>
<img  className="img-fluid img-100 p-image" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${list.cover_image}`}  />

</td>
                 <td>
					<span style={{display:'block', width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{list.headline}</span>
				 </td>

				 <td>{FormatNumber(list.price)}  </td>



                    <td>
					<a href={`/property/edit/${list.code}`} className="btn btn-info btn-sm" rel="noopener noreferrer"><i className="bi bi-eye"></i> View</a>
                    </td>


                  


                    <td>
			 <button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemoveProduct(list.code)}  title="Delete"><i className="bi bi-trash"></i> Remove</button></td>
                  </tr>)}
                 
                </tbody>
              </table>


                    </div>
                  </div>
                </div>
   </div>

</main>
  </Template>
  )
}

export default EditCollection