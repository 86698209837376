import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AddProduct from './products/AddProduct';
import Product from './products/Product';
import Dashboard from './pages/Dashboard';
import Orders from './products/Orders';
import AddUser from './Users/AddUser';
import ChangePassword from './Users/Change_Password';
import Collections from './collections/Collections';
import AddCollection from './collections/AddCollection';
import EditProduct from './products/EditProduct';
import EditUser from './Users/EditUser';
import Users from './Users/Users';
import Category from './pages/Category';
import AddSlider from './sliders/AddSlider';
import EditSlider from './sliders/EditSlider';
import Sliders from './sliders/Sliders';
import Setup from './systems/Setup';
import BulkMessage from './pages/BulkMessage';
import EditCollection from './collections/EditCollection';
import Amenity from './pages/Amenity';

const ProtectedView =()=>{

    
        return ( <>
       
       <Routes>

<Route  path="/property/add" element={<AddProduct /> } /> 
<Route  path="/property/edit/:code" element={<EditProduct /> } /> 
<Route  path="/properties" element={<Product /> } /> 
<Route  path="/dashboard" element={<Dashboard /> } /> 
<Route  path="/category" element={<Category /> } /> 
<Route  path="/clients" element={<Orders /> } /> 
<Route  path="/users" element={<Users /> } /> 
<Route  path="/user/add" element={<AddUser /> } /> 
<Route  path="/sliders" element={<Sliders /> } /> 
<Route  path="/slider/add" element={<AddSlider /> } /> 
<Route  path="/slider/edit/:code" element={<EditSlider /> } /> 
<Route  path="/user/edit/:code" element={<EditUser /> } />
<Route  path="/user/password" element={<ChangePassword /> } /> 
<Route  path="/collection/add" element={<AddCollection /> } /> 
<Route  path="/collection/edit/:code" element={<EditCollection /> } /> 
<Route  path="/collections" element={<Collections /> } /> 
<Route  path="/setup" element={<Setup /> } /> 
<Route  path="/send_message" element={<BulkMessage /> } /> 
<Route  path="/amenity" element={<Amenity /> } /> 
  {/*  <Route  path="/project/dashboard" element={<ProjectDashboard />} />


   

        <Route  path="*" element={<Error404 />} /> 
 */}
        </Routes>
      </>
    );
  
}
export default ProtectedView;
