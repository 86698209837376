import React, { useState } from 'react'

const Footer =()=> {
  const scrollTop =()=>{
    window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
}


  const [displayScroll, setDisplayScroll] = useState(false)
    window.addEventListener('scroll', function(event){
        if(this.scrollY>300){
            setDisplayScroll(true)
        }else{
            setDisplayScroll(false)
        }
    })


  return (
   <>
  
<footer id="footer" className="footer">
  <div className="copyright">
    &copy; Copyright <strong><span>Rentals</span></strong>. All Rights Reserved
  </div>
</footer>
{displayScroll? <a onClick={scrollTop}
href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
:''}

</>


  )
}

export default Footer