export const compare=(a:any,b:any)=>{
    let result = 0;
    if(Number(a.order)>Number(b.order)){
        result = 1
    }else if (Number(a.order)<Number(b.order)){
        result = -1
    }
    return result
    }

    export const FormatNumber = (num:Number)=>{
        let newNumber = Number(num)
        return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }