import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import Template from '../components/Template'
import axios from 'axios'
import Select, { components } from 'react-select'
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'
import TextEditor from '../components/editor'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify'
const AddProduct =()=> {
	const [productCode, setProductCode] = useState('pd-'+Math.random().toString(36).substring(2,9))
let Initial = {
    offer_type:'',
    headline:'',
    price:'',
    expire_date:'',
    property_status:'',
    address:'',
    country:'',
    province:'',
    city:'',
    size:'',
    bedroom:'0',
    bathroom:'0',
    room:'0',
    floor:'0',
    garage:'0',
    description:'',
    category:[] as any,
    rating:'1',
    youtube:'',
    google_map:'',
    reference_no:'',
    publish:'',
    features:'',
    cover_image:'',
    image_list:'',
    related_product:'',
    page_name:'',
    meta_title:'',
    meta_keyword:'',
    meta_description:'',
    staff_create:staffCode

}
  const [property, setProperty] = useState(Initial);

  const [features, setFeatures] = useState([] as any);

  const [category, setCategory] = useState([] as any)
  const [errors, setErrors] = useState({
    category:'',
    offer_type:'',
    country:'',
    price:'',
    address:'',
    reference_no:'',
    publish:'',
    rating:'',
    headline:'',
    property_status:''
  });

  const handleSelectNormal = (option:any, action:any)=>{
    setProperty({...property, [action.name]:option});
      setErrors({...errors, [action.name]:''})
       }


  const handleSelect = (option:any, action:any)=>{
    setProperty({...property, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }

       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	

        if(name==='price'){
          value = value.replace(/[^0-9]/g, "")
        }
        setProperty({...property, [name]:value});
       setErrors({...errors, [name]:''});
     }

     const handleChangeArea =(event:ChangeEvent<HTMLTextAreaElement>)=>{
      let {name, value} = event.target;	
      setProperty({...property, [name]:value});
     setErrors({...errors, [name]:''});
   }



   const fetchAmenity = async()=>{
       
    let url = ServerUrl+'/amenity/view'
    await  axios.get(url, config).then((result:any)=>{
   
             if(Array.isArray(result.data.data) && result.data.data.length!==0){
           
              setFeatures(result.data.data)
               }
             })
             .catch((error)=>{
                 
          ErrorModal('oops', error.message)
             })
             } 


   const fetchCategory = async()=>{
    let url = ServerUrl+'/category/view'
  await  axios.get(url, config).then((result:any)=>{
  
    if(Array.isArray(result.data.data) && result.data.data.length!==0){

      setCategory(result.data.data)
      }
    })
    .catch((error)=>{
      ErrorModal('oops', error.message)
    })
    } 

    const handleEditorChange = (content:any, editor:any) => {
      setProperty({...property, description:content});
    }


    const handleChangeFeatures =(event:ChangeEvent<HTMLInputElement>)=>{
      const {name, value} = event.target;

      const newFeatures = features.map((data:any)=>{
          if(String(data.id) === String(name)){
              return {...data, 
                  status:data.status==='true'?'false':'true'
              }
              };
              return data;
      })
      setFeatures(newFeatures)
  }

          

    const handleSubmit =(event: FormEvent<HTMLButtonElement>, ref:string)=>{
                  event.preventDefault();
              
                  let error = {...errors}; 
                  let formIsValid = true;
              
              let msg ='This field is required';
              
              if(!property.price){
                  error.price =msg;
                  formIsValid = false;
              } 
              
              if(!property.rating){
                error.rating =msg;
                formIsValid = false;
            } 
           
            if(property.category.length===0){
              error.category=msg;
              formIsValid = false;
          }

            if(!property.headline){
              error.headline =msg;
              formIsValid = false;
          } 

              if(!property.offer_type){
                error.offer_type =msg;
                formIsValid = false;
            }
              
            if(!property.property_status){
              error.property_status =msg;
              formIsValid = false;
          }
             
          if(!property.publish){
            error.publish =msg;
            formIsValid = false;
        }

          
          if(!property.country){
            error.country =msg;
            formIsValid = false;
        } 


        if(!property.reference_no){
          error.reference_no =msg;
          formIsValid = false;
      }


        if(!property.address){
          error.address =msg;
          formIsValid = false;
      }


              if(!formIsValid){
              setErrors(error) 
              }
              
              
              if(formIsValid) {
              

                let amenity = []
    var newMenu = JSON.parse(JSON.stringify(features));
    for (var i in newMenu){
            if(newMenu[i].status==='true'){
              amenity.push(newMenu[i].id)
            }
          }

                  LoadingModal()
               const fd = new FormData();
                  Object.entries(property).forEach(([key, value]) => {
                          fd.append(key, String(value));
                      });
              
             let slug =  productCode+property.headline.replace(/[^a-zA-Z0-9 ]/g, ' ').replace(/,?[ ]/g, '-')
             
                      fd.append('code', productCode);
                      fd.append('slug', slug);
                      fd.append('property_category', property.category.value);
                      fd.append('amenity', JSON.stringify(amenity, null, 2));

                 let url = ServerUrl+'/property/add';
                axios.post(url, fd, config)
                .then(response =>{
                  if(response.data.status === 200){
                   SuccessModal(response.data.message)
                   setTimeout(() => {
                    
                   window.open(ref, '_self')
                   }, (2000));
                            } else{
                              ErrorModal('', response.data.message)
                            }   
                        })
                        .catch((error)=>{
                         ErrorModal('oops', error.message)
                        }) 
                  }
              
              }


useEffect(()=>{
  fetchCategory()
  fetchAmenity()
}, [])

  return (<Template> 

<main id="main" className="main">

<div className="pagetitle">
  <h1>Add New Property</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item">Property</li>
      <li className="breadcrumb-item active">Add Property</li>
    </ol>
<div>
    <button className='btn btn-success ' onClick={(e)=>handleSubmit(e, '/properties')}> <i className="bi bi-check2-circle"></i> Save Property</button>&nbsp;

    <button className='btn btn-warning ' onClick={(e)=>handleSubmit(e, '/property/edit/'+productCode)}> <i className='bi bi-pencil'></i>Save & Edit</button>
    </div>

  </nav>

  
</div>





<section className="section">
  <div className="row">

    <div className="col-lg-12">

      <div className="card">

      <h5 className="card-header">Basic Details</h5>
        <div className="card-body ">
         

        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Offer Type <span className="text-danger">*</span></label>
                  <div className="col-sm-4">


            <Select  options={[
                    { value: 'For Sale', label: 'For Sale' },
                    { value: 'For Rent', label: 'For Rent'},
                                ]} 
                                className={errors.offer_type?"form-control form-control-danger":""}
                        onChange={handleSelect}  name="offer_type"
                        value={{value:property.offer_type, label:property.offer_type}} 
                        styles={styles} 
                 />

                    
                  </div>


                  <label className="col-sm-2 col-form-label">Property Category <span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                   
                <Select      options={
                             category&&category.map((list:any, id:number)=>{
                                      return {key:id, value:list.code, label:list.title}
                                          })} 

                                className={errors.category?"form-control form-control-danger":""}
                                onChange={handleSelectNormal}  name="category"
                                value={property.category} 
                                styles={styles}  
                 />
                   
                  </div>


                </div>



        <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Headline<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" name="headline" value={property.headline} onChange={handleChange} className={errors.headline?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}  />
                    <i>if you leave empty, it will look like 'For sale Apartment in New York, Price: $240,000'</i>
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Price ({CURRENCY})<span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                    <input type="text"  className={errors.price?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="price" value={property.price} onChange={handleChange} />
                  </div>


                  <label className="col-sm-2 col-form-label">Property Status<span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                    

          <Select  options={[
                    { value: 'Commercial', label: 'Commercial' },
                    { value: 'Residential', label: 'Residential'}
                                ]} 
                                className={errors.property_status?"form-control form-control-danger form-control-lg":""} 
                        onChange={handleSelect}  name="property_status"
                        value={{value:property.property_status, label:property.property_status}} 
                        styles={styles} 
                 />
                  </div>
                </div>


                <hr/>
                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Location<span className="text-danger">*</span></label>
                  <div className="col-sm-10">
                    <input type="text" className={errors.address?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}   name="address" value={property.address} onChange={handleChange} />
                    <i>Includes Landmark and Neighborhood</i>
                  </div>
                </div>

                
                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-3">
                  <label className=" col-form-label">Country <span className="text-danger">*</span></label>
                  
                    <input type="text"  className={errors.country?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="country" value={property.country} onChange={handleChange} />
                  </div>


                  <div className="col-sm-3">
                  <label className=" col-form-label">Province/State</label>
                 
                    <input type="text" className="form-control form-control-lg" name="province" value={property.province} onChange={handleChange} />
                  </div>

                  <div className="col-sm-3">
                  <label className=" col-form-label">City/Town</label>
                
                    <input type="text" className="form-control form-control-lg" name="city" value={property.city} onChange={handleChange} />
                  </div>
                </div>




                <hr/>

                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-3">
                  <label className=" col-form-label">Size (sq. feet or acres):</label>
                  
                    
                  <input type="text" className="form-control form-control-lg" name="size" value={property.size} onChange={handleChange} />
                  </div>


                  <div className="col-sm-3">
                  <label className=" col-form-label">Bedrooms:</label>
                 
                 

<Select  options={[
                  { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4' },
                    { value: '5', label: '5'},
                    { value: '6', label: '6'},
                    { value: '7+', label: '7+'},
                                ]} 
                       
                                onChange={handleSelect}  name="bedroom"
                                value={{value:property.bedroom, label:property.bedroom}} 
                        styles={styles} 
                 />
                  </div>

                  <div className="col-sm-3">
                  <label className=" col-form-label">Bathrooms:</label>
                
                 

<Select  options={[
    { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4' },
                    { value: '5', label: '5'},
                    { value: '6', label: '6'},
                    { value: '7+', label: '7+'},
                                ]} 
                       
                                onChange={handleSelect}  name="bathroom"
                                value={{value:property.bathroom, label:property.bathroom}} 
                        styles={styles} 
                 />
                  </div>
                </div>




                <div className="row mb-3 mt-3">
                <div className="col-sm-2"></div>

                <div className="col-sm-3">
                  <label className=" col-form-label">Rooms:</label>
                  
                    


                  <Select  options={[
                      { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4' },
                    { value: '5', label: '5'},
                    { value: '6', label: '6'},
                    { value: '7+', label: '7+'},
                                ]} 
                       
                                onChange={handleSelect}  name="room"
                                value={{value:property.room, label:property.room}} 
                        styles={styles} 
                 />
                  </div>


                  <div className="col-sm-3">
                  <label className=" col-form-label">Floors in building:</label>
                 
                 


                  <Select  options={[
                      { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4' },
                    { value: '5', label: '5'},
                    { value: '6', label: '6'},
                    { value: '7+', label: '7+'},
                                ]} 
                       
                                onChange={handleSelect}  name="floor"
                                value={{value:property.floor, label:property.floor}} 
                        styles={styles} 
                 />
                  </div>

                  <div className="col-sm-3">
                  <label className=" col-form-label">Garage</label>
                
                 


                  <Select  options={[
                      { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4' },
                    { value: '5', label: '5'},
                    { value: '6', label: '6'},
                    { value: '7+', label: '7+'},
                                ]} 
                       
                                onChange={handleSelect}  name="garage"
                                value={{value:property.garage, label:property.garage}} 
                        styles={styles} 
                 />
                  </div>
                </div>


        </div>
      </div>

    </div>
  </div>
</section>


<div className="accordion mb-3" id="accordionAdvance">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingAdvance">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvance" aria-expanded="true" aria-controls="collapseAdvance">
                    Advance Details
                    </button>
                  </h2>
                  <div id="collapseAdvance" className="accordion-collapse collapse show" aria-labelledby="headingAdvance" data-bs-parent="#accordionAdvance" >
                    <div className="accordion-body">



                    <div className=" row mb-20">


      <label className="col-sm-2 col-form-label">Property Description</label>

<div className="col-sm-10">

        <div className="input-group">

 <div style={{ width: '100%'}}> 
            
  <TextEditor
  description={property.description}
  handleEditorChange ={handleEditorChange}
      height={300}        
    /> 

          </div>
</div> 
</div>

</div>


<div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Expiry Date </label>
                  <div className="col-sm-4">

                  <input type="date" className="form-control form-control-lg" name="expire_date" value={property.expire_date} onChange={handleChange} />

                    
                  </div>


                  <label className="col-sm-2 col-form-label">Rating <span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                   
<Select  options={[
    { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4' },
                    { value: '5', label: '5'}
                                ]}
                                className={errors.rating?"form-control form-control-danger form-control-lg":""} 
                     onChange={handleSelect}  name="rating"
                                value={{value:property.rating, label:property.rating}} 
                        styles={styles} 
                 />
                   
                  </div>


                </div>

<div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Youtube Video Link</label>
                  <div className="col-sm-10">


                  <input type="text" className="form-control form-control-lg" name="youtube" value={property.youtube} onChange={handleChange} />

                    <i>e.g.   SxSP2mv_pVs   or   https://youtu.be/SxSP2mv_pVs</i>
                  </div>
                </div>


           



                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Google Map Address</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control form-control-lg" name="google_map" value={property.google_map} onChange={handleChange} />
                    <i>e.g https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63865!</i>
                  </div>
                </div>


                <div className="row mb-3 mt-3">
                  <label className="col-sm-2 col-form-label">Reference Number <span className="text-danger">*</span></label>
                  <div className="col-sm-4">

                  <input type="text" className={errors.reference_no?"form-control form-control-danger form-control-lg":"form-control form-control-lg"}   name="reference_no" value={property.reference_no} onChange={handleChange}/>

                    
                  </div>


                  <label className="col-sm-2 col-form-label">Published <span className="text-danger">*</span></label>
                  <div className="col-sm-4">
                   
          <Select  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No'},
                                ]} 
                                className={errors.publish?"form-control form-control-danger form-control-lg":""} 
                                onChange={handleSelect}  name="publish"
                                value={{value:property.publish, label:property.publish}} 
                        styles={styles} 
                 />
                   
                  </div>


                </div>

                    </div>
                  </div>
                </div>
   </div>





<div className="accordion mb-3" id="accordionFeatures">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingFeatures">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFeatures" aria-expanded="true" aria-controls="collapseFeatures">
                    Property Amenities
                    </button>
                  </h2>
                  <div id="collapseFeatures" className="accordion-collapse collapse show" aria-labelledby="headingFeatures" data-bs-parent="#accordionFeatures" >
                    <div className="accordion-body">



                  
                    <div className="row mb-3">
                  
                    {features&&features.map((list:any, index:number)=><div key={index}className="col-sm-3">
                   <div  className="form-check">
                      <input className="form-check-input" onChange={handleChangeFeatures} name={list.id} value={list.status} type="checkbox" id={list.id} checked={list.status==='true'?true:false} />
                      <label className="form-check-label" >
                      {list.title}
                      </label>
                    </div>
             </div>)}
                </div>




                    </div>
                  </div>
                </div>
   </div>



<div className="accordion mb-3" id="accordionPicture">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingPicture">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePicture" aria-expanded="true" aria-controls="collapsePicture">
                    Property Pictures
                    </button>
                  </h2>
                  <div id="collapsePicture" className="accordion-collapse collapse show" aria-labelledby="headingPicture" data-bs-parent="#accordionPicture" >
                    <div className="accordion-body">
                    <p>
          You need to save the property before you can upload pictures for this item page.
					</p>
                    </div>
                  </div>
                </div>
   </div>


   <div className="accordion mb-3" id="accordionRelated">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingRelated">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRelated" aria-expanded="true" aria-controls="collapseRelated">
                    Related Products
                    </button>
                  </h2>
                  <div id="collapseRelated" className="accordion-collapse collapse " aria-labelledby="headingRelated" data-bs-parent="#accordionRelated" >
                    <div className="accordion-body">
                    <p>
                    The Related Products option provides the opportunity to advertise products that are not part of the selected category, to your visitors. These products are displayed on the product details pages.	</p>
                    </div>
                  </div>
                </div>
   </div>


<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">

        <h5 className="card-header">Search Engine Optimization (SEO)</h5>

						<div className="card-body"> 
					  <div className="form-group row mt-3 mb-3">
                 
                  <label className=" col-sm-3 col-form-label">Page Name</label>

                          
                    <div className="col-sm-9 ">
                
                    <input type="text" className="form-control form-control-lg" name="page_name" 
                    value={property.page_name} onChange={handleChange} />
                      </div>
                  </div>


                  <div className="form-group row mb-3">
                  <label className=" col-sm-3 col-form-label">Meta Title</label>
                          
                    <div className="col-sm-9 ">
                    
                    <input type="text" className="form-control form-control-lg" name="meta_title" 
               value={property.meta_title} onChange={handleChange} />
                  </div></div>


                  <div className="form-group row mb-3">
                  <label className=" col-sm-3 col-form-label">Meta Keyword</label>
                          
                    <div className="col-sm-9 ">
                    
                    <input type="text" className="form-control form-control-lg" name="meta_keyword" 
               value={property.meta_keyword} onChange={handleChange} />
                  </div></div>



                  <div className="form-group row mb-3">
                  <label className=" col-sm-3 col-form-label">Meta Description</label>
                          
                    <div className="col-sm-9 ">
                    
             
                    <textarea  className="form-control" rows={5} name="meta_description" 
                    value={property.meta_description} onChange={handleChangeArea} >{property.meta_description}</textarea>


                  </div></div>



					</div>
	</div></div></div>
  
  </section>
			



</main>

    
    
</Template>
  )
}

export default AddProduct