import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Template from '../components/Template'

import Select, { components } from 'react-select'
import axios from 'axios'
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'
import { usePaginated, DOTS } from '../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { ImagesUrl } from '../components/Includes';
import { CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';
import { FormatNumber } from '../components/GlobalFunction'

const Users =()=> {


    const [loading, setLoading] = useState(false);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
 
    const [category, setCategory] = useState([] as any)
    const [search, setSearch]= useState({
      is_active:'',
      email:'',
      filterText:'',
      fullname:'',
        selectAll:false,
        totalSelected:0,
        group:[] as any,
       

    })

  
    const handleResetSearch =()=>{

      setSearch({
        is_active:'',
        email:'',
        fullname:'',
        filterText:'',
          selectAll:false,
          totalSelected:0,
          group:[] as any,
       })
  }

    const handleClear =()=>{
      setSearch({...search, filterText:''});
      setFilterContent(content)
  }


    const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setSearch({...search, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.headline.toLowerCase().includes(value.toLowerCase()) || 
              item.email.toLowerCase().includes(value.toLowerCase()) ||
              item.fullname.toLowerCase().includes(value.toLowerCase()) ||
              item.group_code.toLowerCase().includes(value.toLowerCase()) ||
              item.gender.toLowerCase().includes(value.toLowerCase()) ||
              item.telephone.includes(value),
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }


  
  
  const handleSelectChange =()=>{
      let status = search.selectAll ===false?'true':'false';
      const newContent = filterContent.map((item:any)=>{
          return {...item, 
              is_check:status,
          }
    })
  
    var i = 0;
    newContent.forEach((element:any) => {
        if(element.is_check=== 'true')
       i++
      });
  
      setFilterContent(newContent)
  
      setSearch({...search, selectAll:!search.selectAll, totalSelected:i})
  
     }



     const DeleteModal =(row:any)=>{

      let url = ServerUrl+'/user/delete_one/'+row.code;
      const fd = new FormData();
           fd.append('photo', row.photo); 

      Swal.fire({
        title: 'Are you really sure?',
        text: "This is very dangerous, you shouldn't do it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
      preConfirm: async() => {
        return await axios.post(url, fd, config).then(response=>{
         if(response.data.status!==200){
              throw new Error(response.statusText)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
        confirmButtonText: 'Yes, I am!'
      }).then((result:any) => {
        if (result.isConfirmed && result.value.status===200) {
         
          let rs =filterContent.filter((item:{id:number})=>item.id !==row.id)
              setContent(rs)
              setFilterContent(rs)
           Swal.fire(
                      'Deleted!',
                      'Record has been deleted.',
                      'success'
                    ) 
        }
      })
    }


    const fetchContent = async()=>{
  LoadingModal()

           const fd = new FormData();
           fd.append('email', search.email); 
           fd.append('is_active', search.is_active);
           fd.append('fullname', search.fullname);
           if(search.group.length!==0){
            fd.append('group_code', search.group.value); 
           }
    

           let url = ServerUrl+'/users/view'
         await  axios.post(url, fd, config).then((result:any)=>{
 
           if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
               setFilterContent(result.data.data)
             setContent(result.data.data)
             }
           })
           .catch((error)=>{
               
        ErrorModal('oops', error.message)
           }).finally(()=>{
               closeModal()
           })
           } 


   
    
           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
         }

         const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
         }


         const handleSelect = (option:any, action:any)=>{
          setSearch({...search, [action.name]:option.value});
             }


         const TableData =()=>{

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                 {range}  </div>
            
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
                        const columns = [
            
                             {
                                name: 'Picture',
                                cell: (row:any) => 
                                <img  className="img-fluid img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/users/${row.photo}`}  />
                           , 
                                sortable: true,
                                reorder:true,
                                width:'100px'
                            }, 
                            {
                              name: 'fullname',
                              cell: (row:any) => row.fullname, 
                              sortable: true,
                              reorder:true,
                              wrap:true,
            
                          },
                            {
                                name: `Email Address`,
                                selector: (row:any) => row.email,
                                sortable: true,
                                reorder:true,
                                width:'150px'
                            },
                            {
                                name: 'Telephone',
                                selector: (row:any) => row.telephone,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },
                            {
                              name: 'Group',
                              selector: (row:any) => row.group_code,
                              sortable: true,
                              reorder:true,
                              wrap:true,
                          },

                            {
                                name: 'Status',
                                selector: (row:any) => row.is_active,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },
                            
                            {
                                 name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='col'>  
                                   
                                     <a href={"/user/edit/"+row.code} className="btn btn-info btn-sm "   ><i className='bi bi-pencil'></i> </a>&nbsp;
            
            
                             <button type='button' className="btn btn-danger btn-sm" onClick={()=>DeleteModal(row)}><i className='bi bi-trash'></i> </button> 
                                
                                </div>,
                                    left:true,
                                          
                
                            },
                        ];
                        
                    
                    
                    
                        return <>
                        
            
                        <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    </>
                    }




            useEffect(()=>{
              fetchContent()
            }, [])

  return (<Template> 

<main id="main" className="main">
<div className="pagetitle">
  <h1>Users</h1>
  <nav >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item active"> Users</li>
    </ol>
  </nav>
</div>








<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Search User</h5>

<div className="card-body"> 
<div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row mb-3 mt-3">

                    <label className="col-sm-4 col-form-label">Email Address</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange} name="email" value={search.email} />
                        </div>
                    </div>




                    <div className="form-group row mb-3">
                      <label className="col-sm-4 col-form-label">User Group</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">

                    <Select  options={[
                    { value: 'Administrator', label: 'Administrator' },
                    { value: 'Staff', label: 'Staff'},
                                ]} 
                       
                                onChange={handleSelect}  name="group_code"
                        value={{value:search.group, label:search.group}} 
                 />
                    
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row mb-3 mt-3">
                <label className="col-sm-4 col-form-label">Fullname</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="fullname" value={search.fullname} />
                        </div>
                    </div>
                   
                    <div className="form-group row mb-3">
                    <label className="col-sm-4 col-form-label">Is Active</label>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="published" value={search.is_active} className="form-control" onChange={handleChangeSelect}>
                    <option value="all">All</option>
                        <option value="true">Activated</option>
                        <option value="false">Deactivated</option>
                    </select>
                </div>
                    </div>
                </div>
                </div>
            


            <div className="row mt-3"> 
            
            <div className="col-lg-7 d-flex justify-content-end">
           

            <button type="button" className="btn btn-secondary  " onClick={handleResetSearch}  ><i className="bi bi-arrow-clockwise"></i> Clear</button>&nbsp;
	<button type="button" className="btn btn-primary " onClick={fetchContent}  ><i className="bi bi-search"></i> Search</button>
   


    </div>
            
            </div>
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>










            <section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">All Users</h5>

<div className="card-body"> 


<div className="row mt-3 mb-3">

<div className=" col-md-8 ">
  
   <a href='/user/add' className="btn btn-outline-success btn-sm " ><i className="fas fa-trash"></i> Add New User</a>
  
                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-secondary " >
                          <i className="bi bi-arrow-repeat"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>


<TableData />
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>
                            </main>
    </Template>
  )
}

export default Users