import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Template from '../components/Template'
import { ImagesUrl, ServerUrl, config } from '../components/Includes';
import { ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';

import TextEditor from '../components/editor'
import axios from 'axios'
const BulkMessage =()=> {

    const [content, setContent] = useState({
      message:'',
        subject:'',
    })
	
    const [errors, setErrors] = useState({
      message:'',
        subject:'',
        errorMessage:''
    });

    const handleEditorChange = (msg:any, editor:any) => {
      setContent({...content, message:msg});
    }

   
	const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
		event.preventDefault();
	
		let error = {...errors}; 
		let formIsValid = true;
	
	let msg ='This field is required';
	

	

  if(!content.subject){
		error.subject =msg;
		formIsValid = false;
	} 
	
    if(!content.message){
		error.message =msg;
		formIsValid = false;
	} 



	if(!formIsValid){
		setErrors(error) 
		}
		
		
		if(formIsValid) {
		
			LoadingModal()
			const fd = new FormData();
			   Object.entries(content).forEach(([key, value]) => {
					   fd.append(key, String(value));
				   });
				  
				   let url = ServerUrl+'/company/sendmail'
				   axios.post(url, fd, config)
				   .then(response =>{
            console.log(response.data)
					 if(response.data.status === 200){
					  SuccessModal(response.data.message)


							   } else{
                  window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
                  setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
							   }   
						   })
						   .catch((error)=>{
							ErrorModal('oops', error.message)
						   }) 
		}

}





    
           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
           setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }



  return (<Template>

<main id="main" className="main">
<div className="pagetitle">
  <h1>Send Bulk Email To Clients</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="#!">Profile</a></li>
    </ol>
<div>
    <button className='btn btn-success ' onClick={handleSubmit}> <i className="bi bi-envelope"></i> Send Mail</button>&nbsp;

    </div>

  </nav>
</div>




<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Bulk Email</h5>

<div className="card-body"> 



<div className="row mt-3">
						<section className="card-block">
                        
					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Email Subject</label>
		
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} placeholder='e.g  Happy New Month'  name="subject" value={content.subject} className={errors.subject? 'form-control form-control-danger ' : 'form-control '}/>
							</div>
							</div>
					</div>






                    <div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Email Message</label>

				
									
						<div className="col-sm-9 ">
            <div style={{ width: '100%'}}> 
            
            <TextEditor
            description={content.message}
            handleEditorChange ={handleEditorChange}
                height={300}        
              /> 
          
                    </div>
							
							</div>
					</div>



	</section></div>
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>


</main>




  </Template>
  )
}

export default BulkMessage