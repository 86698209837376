import React, {useState, useRef, useEffect, ChangeEvent, FormEvent} from 'react'
import { APP_NAME, Token, staffCode } from './Includes';

import axios from 'axios'
import { ImagesUrl, ServerUrl,  config } from '../components/Includes'
import { ErrorModal } from './notify';
const Header =()=> {


  const [profile, setProfile] = useState([]as any)
	const [content, setContent] = useState([]as any)

  const fetchUser = async()=>{
    if(Token !== undefined){
			  let url = ServerUrl+'/user/edit/'+staffCode
			await  axios.get(url,  config).then((result:any)=>{
			  if(result.data.data&&result.data.data.length!==0){
        
				setContent(result.data.data)
			  
				}else{
				  window.open('/logout', '_self')
				}
			  })
			  .catch((error)=>{
			   ErrorModal('oops', error.message)
        window.open('/logout', '_self')
			  })
			  } 
      }


      const fetchProfile = async()=>{
        let url = ServerUrl+'/company/view'
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
            
            setProfile(result.data.data)
            
            }
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            })
          }


    const openslider =()=>{


    var toggle_sidebar = document.querySelector('#container');
    if (toggle_sidebar?.classList.contains('toggle-sidebar')) {
      toggle_sidebar.classList.remove('toggle-sidebar');
    } else {
      toggle_sidebar?.classList.add('toggle-sidebar');
    }
}


useEffect(()=>{
  fetchUser()
  fetchProfile()
}, [])


  return ( 
    <header id="header" className="header fixed-top d-flex align-items-center">
    
      <div className="d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">

          <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/"+profile.image_url} alt="" />

          <span className="d-none d-lg-block">{profile.business_name}</span>
        </a>
        <i className="bi bi-list toggle-sidebar-btn" onClick={openslider}></i>
      </div>
    
      <div className="search-bar">
        <form className="search-form d-flex align-items-center" method="POST" action="#">
          <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
          <button type="submit" title="Search"><i className="bi bi-search"></i></button>
        </form>
      </div>
    
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
    
          <li className="nav-item d-block d-lg-none">
            <a className="nav-link nav-icon search-bar-toggle " href="#">
              <i className="bi bi-search"></i>
            </a>
          </li>
    
          <li className="nav-item dropdown">
    
            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
              <i className="bi bi-bell"></i>
              <span className="badge bg-primary badge-number">4</span>
            </a>
    
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
              <li className="dropdown-header">
                You have 4 new notifications
                <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
              </li>
              <li>
                      <hr className="dropdown-divider" />
              </li>
    
    
              <li className="notification-item">
                <i className="bi bi-info-circle text-primary"></i>
                <div>
                  <h4>Dicta reprehenderit</h4>
                  <p>Quae dolorem earum veritatis oditseno</p>
                  <p>4 hrs. ago</p>
                </div>
              </li>
    
              <li>
                      <hr className="dropdown-divider" />
              </li>
              <li className="dropdown-footer">
                <a href="#">Show all notifications</a>
              </li>
    
            </ul>
    
          </li>
    
          <li className="nav-item dropdown">
    
            <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
              <i className="bi bi-chat-left-text"></i>
              <span className="badge bg-success badge-number">3</span>
            </a>
    
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
              <li className="dropdown-header">
                You have 3 new messages
                <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
              </li>
              <li>
                      <hr className="dropdown-divider" />
              </li>
    
              <li className="message-item">
                <a href="#">
                  <img src="assets/img/messages-1.jpg" alt="" className="rounded-circle" />
                  <div>
                    <h4>Maria Hudson</h4>
                    <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                    <p>4 hrs. ago</p>
                  </div>
                </a>
              </li>
              <li>
                      <hr className="dropdown-divider" />
              </li>
    
              <li>
                      <hr className="dropdown-divider" />
              </li>
    
              <li className="dropdown-footer">
                <a href="#">Show all messages</a>
              </li>
    
            </ul>
    
          </li>
    
          <li className="nav-item dropdown pe-3">
    
            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">


              <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/users/"+content.photo} alt="Profile" className="rounded-circle" />


              <span className="d-none d-md-block dropdown-toggle ps-2">{content.fullname}</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{content.fullname}</h6>
                <span>{content.group_code}</span>
              </li>
              <li>
                      <hr className="dropdown-divider" />
              </li>
    
              <li>
                <a className="dropdown-item d-flex align-items-center" href={'/user/edit/'+content.code}>
                  <i className="bi bi-person"></i>
                  <span>My Profile</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
    
              <li>
                <a className="dropdown-item d-flex align-items-center" href="/user/password">
                  <i className="bi bi-gear"></i>
                  <span>Change Password</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
    
              <li>
                <a className="dropdown-item d-flex align-items-center" href="#!">
                  <i className="bi bi-question-circle"></i>
                  <span>Need Help?</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
    
              <li>
                <a className="dropdown-item d-flex align-items-center" href="/logout">
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </a>
              </li>
    
            </ul>
          </li>
    
        </ul>
      </nav>
    
    </header>
        
  )
}

export default Header