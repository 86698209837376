import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Template from '../components/Template'

import Select, { components } from 'react-select'
import axios from 'axios'
import { CURRENCY, ServerUrl, config, staffCode, styles } from '../components/Includes'
import { usePaginated, DOTS } from '../components/usePagination';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { ImagesUrl } from '../components/Includes';
import { CustomLoader, ErrorModal, LoadingModal, closeModal } from '../components/notify';
import { FormatNumber } from '../components/GlobalFunction'

const Product =()=> {


    const [loading, setLoading] = useState(false);
    const [filterContent, setFilterContent] = useState([] as any);
    const [content, setContent] = useState([] as any);
 
    const [category, setCategory] = useState([] as any)
    const [search, setSearch]= useState({
      headline:'',
      reference_no:'',
      offer_type:'',
      rating:'',
      filterText:'',
        published:'all',
        category:[] as any,
        selectAll:false,
        totalSelected:0
       

    })

  
    const handleResetSearch =()=>{

      setSearch({
        headline:'',
        reference_no:'',
        offer_type:'',
        rating:'',
        filterText:'',
          published:'all',
          category:[] as any,
          selectAll:false,
          totalSelected:0
       })
  }

    const handleClear =()=>{
      setSearch({...search, filterText:''});
      setFilterContent(content)
  }


    const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	
      setSearch({...search, [name]:value});

      if(value!==''){
          const filteredItems = content.filter(
              (item:any) => item.headline.toLowerCase().includes(value.toLowerCase()) || 
              item.category.toLowerCase().includes(value.toLowerCase()) ||
              item.offer_type.toLowerCase().includes(value.toLowerCase()) ||
              item.publish.toLowerCase().includes(value.toLowerCase()) ||
              item.property_status.toLowerCase().includes(value.toLowerCase()) ||
              item.price.includes(value),
          );

          setFilterContent(filteredItems)
      }else{
          setFilterContent(content)
      } 

   }

    const handleSelectAll =(list:[])=>{
      
      let is_check = list.every((element:any) => {
       return element.is_check === 'true'
      });
      var i = 0;
          list.forEach((element:any) => {
            if(element.is_check === 'true')
           i++
          });
      setSearch({...search, selectAll:is_check, totalSelected:i})
      
         }

const handleCheck =(code:string)=>{
  const newContent = filterContent.map((item:any)=>{
     
  if(item.code === code){
  return {...item, 
      is_check:item.is_check==='false'?'true':'false',
  }
  };
  return item;
  })
  setFilterContent(newContent)
  handleSelectAll(newContent)
  }
  
  
  const handleSelectChange =()=>{
      let status = search.selectAll ===false?'true':'false';
      const newContent = filterContent.map((item:any)=>{
          return {...item, 
              is_check:status,
          }
    })
  
    var i = 0;
    newContent.forEach((element:any) => {
        if(element.is_check=== 'true')
       i++
      });
  
      setFilterContent(newContent)
  
      setSearch({...search, selectAll:!search.selectAll, totalSelected:i})
  
     }



     const DeleteModal =(row:any)=>{

      let url = ServerUrl+'/property/delete_one/'+row.code;
    
      Swal.fire({
        title: 'Are you really sure?',
        text: "This is very dangerous, you shouldn't do it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showLoaderOnConfirm: true,
      preConfirm: async() => {
        return await axios.get(url, config).then(response=>{
         if(response.data.status!==200){
              throw new Error(response.statusText)
            }
            return response.data
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
        confirmButtonText: 'Yes, I am!'
      }).then((result:any) => {
        if (result.isConfirmed && result.value.status===200) {
         
          let rs =filterContent.filter((item:{id:number})=>item.id !==row.id)
              setContent(rs)
              setFilterContent(rs)
           Swal.fire(
                      'Deleted!',
                      'Record has been deleted.',
                      'success'
                    ) 
        }
      })
    }


    const fetchContent = async()=>{
  LoadingModal()

           const fd = new FormData();
           fd.append('headline', search.headline); 
           fd.append('offer_type', search.offer_type);
           fd.append('published', search.published);
           fd.append('rating', search.rating);
           fd.append('reference_no', search.reference_no);
           if(search.category.length!==0){
            fd.append('category', search.category.value); 
           }
    

           let url = ServerUrl+'/property/view'
         await  axios.post(url, fd, config).then((result:any)=>{
 
           if(Array.isArray(result.data.data) && result.data.data.length!==0){
          
               setFilterContent(result.data.data)
             setContent(result.data.data)
             }
           })
           .catch((error)=>{
               
        ErrorModal('oops', error.message)
           }).finally(()=>{
               closeModal()
           })
           } 


    const fetchCategory = async()=>{
      let url = ServerUrl+'/category/view'
    await  axios.get(url, config).then((result:any)=>{
   
      if(Array.isArray(result.data.data) && result.data.data.length!==0){
  
        setCategory(result.data.data)
        }
      })
      .catch((error)=>{
        ErrorModal('oops', error.message)
      })
      } 

    const handleSelectOption = (option:any, action:any)=>{
        setSearch({...search, [action.name]:option});
           }
    
           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
         }

         const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
            let {name, value} = event.target;	
            setSearch({...search, [name]:value});
         }


         const handleSelect = (option:any, action:any)=>{
          setSearch({...search, [action.name]:option.value});
             }


         const TableData =()=>{

            const PaginationWrapper =(props:any)=>{
            
                const handleRowsPerPage = useCallback(
                    (e: React.ChangeEvent<HTMLSelectElement>) => props.onChangeRowsPerPage(Number(e.target.value), props.currentPage),
                    [props.currentPage, props.onChangeRowsPerPage],
                );
                const handleSelectRowsPerPage = useCallback((option:any, action:any)=> props.onChangeRowsPerPage(Number(option.value), props.currentPage),
                [props.currentPage, props.onChangeRowsPerPage],
                )
               
                function getNumberOfPages(rowCount:number, rowsPerPage:number) {
                    return Math.ceil(rowCount / rowsPerPage);
                  }
                const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
                const lastIndex = props.currentPage * props.rowsPerPage;
                const firstIndex = lastIndex - props.rowsPerPage + 1;
            
            
                    const range =
                    props.currentPage === numPages ?  `Showing ${firstIndex} to ${props.rowCount} of ${props.rowCount} entries`:
            
                    `Showing ${firstIndex} to ${lastIndex} of ${props.rowCount} entries`
            
                
               
                const Pagination = () => {
                   
                    const pageSize = props.rowsPerPage;
                    const totalCount = content.length
                    const siblingCount = 1
                    const currentPage = props.currentPage
                    
                    const paginationRange = usePaginated({ currentPage,  totalCount, siblingCount,  pageSize });
                    
                    if (currentPage === 0 ) {
                      return null;
                    }
                    
                    let lastPage =  paginationRange&&paginationRange[paginationRange.length - 1];
                    return (
                    <nav className="" aria-label="Page navigation sample ">
                      <ul  className="pagination pagination-sm"  >
                        <li className={currentPage === 1 ? ' page-item disabled' : 'page-item'} >
                         <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage - 1)}>Previous</a>
                        </li>
                        {paginationRange&&paginationRange.map((pageNumber, index) => {
                          if (pageNumber === DOTS) {
                            return <li className="pagination-item dots" key={index}>
                             <a className="page-link pointer"  >&#8230;</a>
                            </li>
                          }
                          return (
                            <li className={pageNumber === currentPage?' page-item  active':'page-item '} key={index} >
                              <a className="page-link pointer"  onClick={() =>props.onChangePage(pageNumber)} >{pageNumber}</a>
                              
                            </li>
                          );
                        })}
                    
                    <li  className={currentPage === lastPage  ? ' page-item disabled' : 'page-item'}>
                    <a className="page-link pointer"  onClick={() =>props.onChangePage(props.currentPage + 1)} aria-label="Next">Next</a>
                        </li>
                    
                      </ul> </nav>
                    );
                    
                        }
            
                      
            
                      
            
             return <div className="row mt-3">
                                
            <div className="col-md-5 ">
            
            
               Rows per page: &nbsp; <select name="rowPerPage" id="" className='w-40-h-30 ' onChange={handleRowsPerPage}>
                    <option value="10"> 10</option>
                    <option value="20"> 20</option>
                    <option value="30"> 30</option>
                    <option value="40"> 40</option>
                    <option value="50"> 50</option>
                    <option value={content.length}> All</option>
                </select> &nbsp;&nbsp;
                 {range}  </div>
            
              
            <div className="col-md-7  justify-content-end d-flex">
               <Pagination  />
                </div>
                
            </div>}
            
            
            
            const CheckboxAll =()=>{ 
            
                return <div className="chk-option">
                <div className="checkbox-fade fade-in-primary">
                    <label className="check-task">
                
                     <input type="checkbox"  id="selectAll" checked={search.selectAll} onChange={handleSelectChange} />
                                
                    <span className="cr">
                        <i className="cr-icon fa fa-check txt-default"></i>
                    </span>
                    </label>
                </div>
                </div>}
            
            
            const Checkbox =({code, is_check}:{code:string, is_check:string})=>{ 
            
            return <div className="chk-option">
            <div className="checkbox-fade fade-in-primary">
                <label className="check-task">
            
                 <input type="checkbox"  name={code} id={code} checked={is_check==='true'?true:false} onChange={()=>handleCheck(code)} /> 
                            
                <span className="cr">
                    <i className="cr-icon fa fa-check txt-default"></i>
                </span>
                </label>
            </div>
            </div>}
            
            
            
                        const columns = [
            
                            {
                                name: <CheckboxAll />,
                                cell: (row:any) => <Checkbox code={row.code} is_check={row.is_check} />, 
                                width:'60px'
                            },
                             {
                                name: 'Picture',
                                cell: (row:any) => 
                                <img  className="img-fluid img-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${row.cover_image}`}  />
                           , 
                                sortable: true,
                                reorder:true,
                                width:'100px'
                            }, 
                            {
                              name: 'Headline',
                              cell: (row:any) => row.headline, 
                              sortable: true,
                              reorder:true,
                              wrap:true,
            
                          },
                            {
                                name: `Price(${CURRENCY})`,
                                selector: (row:any) => FormatNumber(row.price),
                                sortable: true,
                                reorder:true,
                                width:'150px'
                            },
                            {
                                name: 'Offer Type',
                                selector: (row:any) => row.offer_type,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },
                            {
                              name: 'Category',
                              selector: (row:any) => row.category,
                              sortable: true,
                              reorder:true,
                              wrap:true,
                          },
                            {
                                name: 'Publish',
                                selector: (row:any) => row.publish,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },

                            {
                                name: 'Status',
                                selector: (row:any) => row.property_status,
                                sortable: true,
                                reorder:true,
                                wrap:true,
                            },
                            
                            {
                                 name: 'Action',
                                cell: (row:any, index:any, column:any, id:any) => <div className='col'>  
                                   
                                     <a href={"/property/edit/"+row.code} className="btn btn-info btn-sm "   ><i className='bi bi-pencil'></i> </a>&nbsp;
            
            
                             <button type='button' className="btn btn-danger btn-sm" onClick={()=>DeleteModal(row)}><i className='bi bi-trash'></i> </button> 
                                
                                </div>,
                                    left:true,
                                          
                
                            },
                        ];
                        
                    
                    
                    
                        return <>
                        
            
                        <DataTable
                        columns={columns}
                        data={filterContent}
                        highlightOnHover
                        pagination
                        paginationComponent={PaginationWrapper}
                        selectableRowsHighlight
                        striped
                        responsive
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                    />
                    </>
                    }


                    const handleDeleteAll =()=>{

                      if(search.totalSelected<1){
                        ErrorModal('oops', 'Please select at least one record to delete ')
                      }else{
                    
                        let propertyList =[] as any;
                        let selectedProperty = filterContent.filter((item:any)=>item.is_check ==='true')
                    
                        if(Array.isArray(selectedProperty)){
                          selectedProperty.forEach((list:any) => {
                            propertyList.push(list.code)
                          });
                        }
                    
                        const fd = new FormData();
                        fd.append('propertyList', JSON.stringify(propertyList, null, 2));
                        let url = ServerUrl+'/property/delete_bulk'
                        Swal.fire({
                          title: 'Are you really sure?',
                          text: "This is very dangerous, you shouldn't do it!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          showLoaderOnConfirm: true,
                        preConfirm: async() => {
                          return await axios.post(url, fd, config).then(response=>{
                           if(response.data.status!==200){
                                throw new Error(response.statusText)
                              }
                              return response.data
                            })
                            .catch(error => {
                              Swal.showValidationMessage(
                                `Request failed: ${error}`
                              )
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading(),
                          confirmButtonText: 'Yes, I am!'
                        }).then((result:any) => {
                          if (result.isConfirmed && result.value.status===200) {
                    
                            let rs =filterContent.filter((item:any)=>item.is_check ==='false')
                           setContent(rs)
                           setFilterContent(rs)
                             Swal.fire(
                                        'Deleted!',
                                        'Record has been deleted.',
                                        'success'
                                      ) 
                          }
                        })
                    
                      }
                    
                     }


            useEffect(()=>{
              fetchCategory()
              fetchContent()
            }, [])

  return (<Template> 

<main id="main" className="main">
<div className="pagetitle">
  <h1>Property</h1>
  <nav >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item active"> Property</li>
    </ol>
  </nav>
</div>








<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Search Properties</h5>

<div className="card-body"> 
<div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row mb-3 mt-3">

                    <label className="col-sm-4 col-form-label">Property Headline</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange} name="headline" value={search.headline} />
                        </div>
                    </div>


                    <div className="form-group row mb-3">
                    <label className="col-sm-4 col-form-label">Category</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                  
                    <Select      options={
                             category&&category.map((list:any, id:number)=>{
                                      return {key:id, value:list.code, label:list.title}
                                          })} 

                                onChange={handleSelectOption}  name="category"
                                value={search.category} 
                 />

                        </div>
                    </div>


                    <div className="form-group row mb-3">
                      <label className="col-sm-4 col-form-label">Property Rating</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">

                    
<Select  options={[
    { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4' },
                    { value: '5', label: '5'}
                                ]}
                     onChange={handleSelect}  name="rating"
                                value={{value:search.rating, label:search.rating}} 
                 />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row mb-3 mt-3">
                <label className="col-sm-4 col-form-label">Reference Number</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="text" className="form-control" onChange={handleChange}  name="reference_no" value={search.reference_no} />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                    <label className="col-sm-4 col-form-label">Offer Type</label>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">

                    <Select  options={[
                    { value: 'For Sale', label: 'For Sale' },
                    { value: 'For Rent', label: 'For Rent'},
                                ]} 
                          
                        onChange={handleSelect}  name="offer_type"
                        value={{value:search.offer_type, label:search.offer_type}} 
                 />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                    <label className="col-sm-4 col-form-label">Property Status</label>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select name="published" value={search.published} className="form-control" onChange={handleChangeSelect}>
                        <option value="all">All</option>
                        <option value="Yes">Published Only</option>
                        <option value="No">Unpublished Only</option>
                    </select>
                </div>
                    </div>
                </div>
                </div>
            


            <div className="row mt-3"> 
            
            <div className="col-lg-7 d-flex justify-content-end">
           

            <button type="button" className="btn btn-secondary  " onClick={handleResetSearch}  ><i className="bi bi-arrow-clockwise"></i> Clear</button>&nbsp;
	<button type="button" className="btn btn-primary " onClick={fetchContent}  ><i className="bi bi-search"></i> Search</button>
   


    </div>
            
            </div>
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>










            <section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">All Properties</h5>

<div className="card-body"> 


<div className="row mt-3 mb-3">

<div className=" col-md-8 ">
  
   <button type="button" className="btn btn-outline-danger btn-sm " onClick={handleDeleteAll} ><i className="bi bi-trash"></i> Delete Selected ({search.totalSelected})</button>
  &nbsp;


   <a href='/property/add' className="btn btn-outline-success btn-sm " ><i className="bi bi-add"></i> Add New</a>
  


                  </div>

    <div className=" col-md-4 ">
    <div className="input-group  " >
                    <input type="text" name="filterText" id="filterText" key="filterText"  onChange={handleSearch} value={search.filterText} className="form-control float-right form-control" placeholder="Type to search" />
                   
                    <div className="input-group-append">
                        <button type="button" onClick={handleClear} className="btn btn-secondary " >
                          <i className="bi bi-arrow-repeat"></i>
                        </button>
                      </div>
                  </div>
      

    </div>
</div>


<TableData />
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>
                            </main>
    </Template>
  )
}

export default Product