import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import axios from 'axios'
import { APP_NAME, ImagesUrl, ServerUrl, Token } from '../components/Includes';
import Cookies from 'js-cookie'
import { ErrorModal, LoadingModal, closeModal } from '../components/notify';
import { encrypt } from '../components/authentication';

const Login =()=> {


  const [profile, setProfile] = useState([]as any)
	const [notice, setNotice] = useState({
    isLoading : false,
    isDataFetching: false
});

const [user, setUser] = useState({
email:'',
password:'',
remember:'No',
date:new Date().toISOString().slice(0,10),
dateTime:new Date().toISOString().slice(0,19)
})
const [errors, setErrors] = useState({
    email:'',
    password:''

});

const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    if(name==='remember'){
        value= value==='Yes'?'No':'Yes'
    }	
   setUser({...user, [name] : value });
   setErrors({...errors, [name]:''});
}

const config = {
    headers: { 
        "Access-Control-Allow-Origin": "*",
        'content-type': 'multipart/form-data'
}
} 
  

const fetchProfile = async()=>{
  let url = ServerUrl+'/company/profile'
    await  axios.get(url,  config).then((result:any)=>{
      if(result.data.data&&result.data.data.length!==0){
      
      setProfile(result.data.data)
      
      }
      })
      .catch((error)=>{
       ErrorModal('oops', error.message)
      })
    }



const handleSubmit =(event: FormEvent<HTMLFormElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';




if(!user.email){
error.email =msg;
formIsValid = false;
} 
if(!user.password){
error.password =msg;
formIsValid = false;
} 

if(!navigator.onLine){
   // Alerts('danger', 'No internet connection')
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

LoadingModal()	
       
    const fd = new FormData();
    fd.append('email', user.email);
    fd.append('password', user.password);

       let url = ServerUrl+'/user/login';
axios.post(url, fd, config)
.then(response =>{
        if(response.data.status === 200){
  
  //1000 * 60 * SESSION_IDEL_MINUTES
  var inSixtyMinutes:any = Date.now() + 419 * 60 * 1000;
  var inOneMinutes:any = Date.now() + 418 * 60 * 1000;
    var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
    
     localStorage.setItem('_expt', inSixtyMinutes)
    localStorage.setItem('_bexpt', inOneMinutes) 


    
  Cookies.set('rhrucd', response.data.code, {expires: inOneHours })
  Cookies.set('rhrgpcd', encrypt(response.data.group_code), {expires: inOneHours })
  Cookies.set('rhrtk', response.data.token, {expires: inOneHours })   
  
  
  var queryString = new URLSearchParams(window.location.search).get("refferer")
            if(queryString!==null){
                window.open(queryString, '_self')
            }else{
                window.open('/dashboard', '_self')
            }   
  
  }else{
    ErrorModal('oops', response.data.message)
  }    
})
.catch((error)=>{
  ErrorModal('oops', error.message)
}).finally(()=>{
   // closeModal()
    })   
}
}


useEffect(()=>{  
  fetchProfile() 

if(Token!==undefined){
window.open('/dashboard', '_self')
   
 }  
},[]); 

  return (<> 
    <main>
    <div className="container">

      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-4">
                <a href="index.html" className="logo d-flex align-items-center w-auto">
                  <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/"+profile.image_url} alt=""  />
                  <span className="d-none d-lg-block">{profile.business_name}</span>
                </a>
              </div>

              <div className="card mb-3">

                <div className="card-body">

                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p className="text-center small">Enter your email &amp; password to login</p>
                  </div>

                  <form className="row g-3 needs-validation" onSubmit={handleSubmit} >

                    <div className="col-12">
                      <label  className="form-label">Email Address</label>

                      <input type="email" id="email" name="email" required autoComplete='off' role={'presentation'} value={user.email} onChange={handleChange} className={errors.email  ? 'form-control formerror ' : 'form-control '} /> 
                      {errors.email&&
                        <div className="col-form-label has-danger">{errors.email&&errors.email} </div>}
                   <div className="invalid-feedback">Please enter your email.</div>
                    </div>

                    <div className="col-12">
                      <label  className="form-label">Password</label>


                <input type="password" id="password" name="password" required autoComplete='off' role={'presentation'} value={user.password} onChange={handleChange} className={errors.password  ? 'form-control formerror ' : 'form-control '} /> 


{errors.password&&
<div className="col-form-label has-danger">{errors.password&&errors.password} </div>}

                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
               
    <input type="checkbox" value={user.remember} name="remember" checked={user.remember ==='Yes'?true:false}  onChange={handleChange} />
                        <label className="form-check-label" >Remember me</label>
                      </div>
                    </div>
                    <div className="col-12">

                    {notice.isLoading ?	<button type="button" disabled className="btn btn-primary w-100"><i className='bi bi-refresh bi-spin'></i> Please wait ... </button>:
				<button type="submit"   className="btn btn-primary w-100"><i className='bi bi-arrow-right '></i> Login</button>}


                    </div>
                 
                  </form>

                </div>
              </div>


            </div>
          </div>
        </div>

      </section>

    </div>
  </main>


    
    
</>
  )
}

export default Login