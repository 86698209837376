import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './Users/Login';
import Template from './components/Template';
import Blank from './pages/Blank';
import ProtectedView from './protected_router';
import Logout from './Users/Logout';

function App() {

   
  return (
    <>
    <Router>
          <Routes>
         <Route  path="/" element={<Login />} />
         <Route  path="/logout" element={<Logout />} />


        
         </Routes>
         <ProtectedView  />
    </Router>

    
</>
  );
}

export default App;
