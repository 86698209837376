import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar =()=> {

  return (  
    <aside id="sidebar" className="sidebar">
    
      <ul className="sidebar-nav" id="sidebar-nav">
    
        <li className="nav-item">
          <Link to='/dashboard'>
          <a className="nav-link collapsed" >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </a></Link>
        </li>
       

        <li className="nav-item">
          <Link to='/category'>
          <a className="nav-link collapsed" >
            <i className="bi bi-menu-button-wide"></i>
            <span>Category</span>
          </a></Link>
        </li>

        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#products" data-bs-toggle="collapse" href="#">
            <i className="bi bi-journal-text"></i><span>Property</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="products" className="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li>
              <a href='/property/add'>
                <i className="bi bi-circle"></i><span>Add a new Property</span>
              </a>
            </li>
           {/*  <li>
              <a href='#!'>
                <i className="bi bi-circle"></i><span>Add Bulk Property</span>
              </a>
            </li>
            <li>
              <a href='#!'>
                <i className="bi bi-circle"></i><span>Import Property</span>
              </a>
            </li> */}
            <li>
              <a href='/properties'>
                <i className="bi bi-circle"></i><span>View All Property</span>
              </a>
            </li>
          </ul>
        </li>

      

        

        <li className="nav-item"><Link to='/clients'>
          <a className="nav-link collapsed" >
            <i className="bi bi-person"></i>
            <span>Clients</span>
          </a></Link>
        </li>



        <li className="nav-heading">Setup</li>


        <li className="nav-item"><Link to='/send_message'>
          <a className="nav-link collapsed" >
            <i className="bi bi-envelope"></i>
            <span>Messages</span>
          </a></Link>
        </li>

        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#website" data-bs-toggle="collapse" href="#">
            <i className="bi bi-gem"></i><span>Website</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="website" className="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li>
              <a href='/collections'>
                <i className="bi bi-circle"></i><span>Collections</span>
              </a>
            </li>
            <li>
              <a href='/sliders'>
                <i className="bi bi-circle"></i><span>Sliders</span>
              </a>
            </li>
          </ul>
        </li>


        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#users" data-bs-toggle="collapse" href="#">
            <i className="bi bi-layout-text-window-reverse"></i><span>Users</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="users" className="nav-content collapse " data-bs-parent="#sidebar-nav">
            <li>
              <a href='/user/add'>
                <i className="bi bi-circle"></i><span>Add New User</span>
              </a>
            </li>
            <li>
              <a href='/users'>
                <i className="bi bi-circle"></i><span>View All User</span>
              </a>
            </li>
          </ul>
        </li>


        <li className="nav-item">
          <a className="nav-link collapsed" data-bs-target="#systems" data-bs-toggle="collapse" href="#">
            <i className="bi bi-gear"></i><span>Systems</span><i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="systems" className="nav-content collapse " data-bs-parent="#sidebar-nav">
          
            <li>
              <a href='/setup'>
                <i className="bi bi-circle"></i><span>Basic Details</span>
              </a>
            </li>
            <li>
              <a href='/amenity'>
                <i className="bi bi-circle"></i><span>Property Amenity</span>
              </a>
            </li>
            <li>
              <a href='#!'>
                <i className="bi bi-circle"></i><span>Email Template</span>
              </a>
            </li>
            
          </ul>
        </li>
    
        <li className="nav-item"><Link to='/logout'>
          <a className="nav-link collapsed" >
            <i className="bi bi-box-arrow-in-right"></i>
            <span>Log Out</span>
          </a></Link>
        </li>

      </ul>
    
    </aside>
  )
}

export default Sidebar