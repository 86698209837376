import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'

import axios from 'axios'
import {  ImagesUrl, ServerUrl,  config } from './Includes';

import { Helmet } from 'react-helmet';
const  Template = (props:{children?:React.ReactNode})=> {

  const [profile, setProfile] = useState([]as any)
  const fetchProfile = async()=>{
    let url = ServerUrl+'/company/profile'
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
        
        setProfile(result.data.data)
        
        }
        })
      }



useEffect(()=>{  
  fetchProfile()  
},[]); 

  return (
    <>

  
  <Helmet>
          <title>{profile.business_name}</title>
        <meta name="description" content={profile.page_description} />
       
    
        <meta property="og:title" content={profile.business_name}/> 
        <meta property="og:description" content={profile.page_description} /> 
        <meta property="og:image" content={ImagesUrl+'/'+profile.image_url} />  

        <meta property="og:url" content={profile.website}/> 
      
<meta property="og:type"          content="website" />

    
<link rel="icon" href={ImagesUrl+'/'+profile.image_url} />
        <meta name="twitter:title" content={profile.business_name} /> 
        <meta name="twitter:description" content={profile.page_description}/> 
        <meta name="twitter:image" content={ImagesUrl+'/'+profile.image_url} />  
        <meta name="twitter:url" content={profile.website} /> 

    </Helmet>
         <Sidebar />
        <Header />

        {props.children}

       
        <Footer />

    </>
  )
}

export default Template