import React, { ChangeEvent, FormEvent, useState } from 'react'
import Template from '../components/Template'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify';
import { ServerUrl, config } from '../components/Includes';

import axios from 'axios'
const AddCollection =()=> {
    const [content, setContent] = useState({
		code:'c'+Math.random().toString(36).substring(2,9),
		title:'',
		collection_type:'property',
		layout_type:'',
		display_order:1,
		is_active:'true',
		item_list:'[]',
	})

    const [errors, setErrors] = useState({
        layout_type:'',
        title:'',
		collection_type:''
    });

   
    
           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }

         const handleChangeSelect =(event:ChangeEvent<HTMLSelectElement>)=>{
            let {name, value} = event.target;	
			if(name==='is_active'){
				value = value==='false'?'true':'false'
			  } 
			setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }


		 const handleSubmit =(event: FormEvent<HTMLButtonElement>, ref:string)=>{
			event.preventDefault();
		
			let error = {...errors}; 
			let formIsValid = true;
		
		let msg ='This field is required';
		
	
		if(!content.title){
			error.title=msg;
			formIsValid = false;
		} 
	
	  if(!content.collection_type){
			error.collection_type =msg;
			formIsValid = false;
		} 
		
		if(!content.layout_type){
			error.layout_type =msg;
			formIsValid = false;
		} 
		if(!formIsValid){
			setErrors(error) 
			}
			
			
			if(formIsValid) {
			
				LoadingModal()
				const fd = new FormData();
				   Object.entries(content).forEach(([key, value]) => {
						   fd.append(key, String(value));
					   });
					  
	
					   
					   let url = ServerUrl+'/collection/add';
					   axios.post(url, fd, config)
					   .then(response =>{
						 if(response.data.status === 200){
						  SuccessModal(response.data.message)
						window.open(ref, '_self');
	
								   } else{
									 ErrorModal('', response.data.message)
								   }   
							   })
							   .catch((error)=>{
								ErrorModal('oops', error.message)
							   }) 
			}
	
	}
	


		 
  return (<Template>

<main id="main" className="main">
<div className="pagetitle">
  <h1>Add Collection</h1>

  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
      <li className="breadcrumb-item"><a href="#!">Collection</a></li>
      <li className="breadcrumb-item active">Add Collection</li>
    </ol>

    <div>
    <button className='btn btn-success ' onClick={(e)=>handleSubmit(e, '/collections')}> <i className="bi bi-check2-circle"></i> Save Collection</button>&nbsp;

    <button className='btn btn-warning ' onClick={(e)=>handleSubmit(e, '/collection/edit/'+content.code)}> <i className='bi bi-pencil'></i>Save & Edit</button>
    </div>
  </nav>
</div>





<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Add Collection</h5>

<div className="card-body"> 



<div className="row">
<div className="col-sm-12">

						<section className="card-block">
                        
					<div className="form-group row mb-3 mt-3">

                    <label className="col-sm-3 col-form-label">Title</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="title" value={content.title} className='form-control '/>
						<span className="text-danger"> *</span>
							</div>
                            <i>Collection title as you want it to appear on home page</i>

							<span className="error">{errors.title}</span>
							</div>
					</div>
					
					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Collection Type</label>

					
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="collection_type" value={content.collection_type} className="form-control" onChange={handleChangeSelect}>
							<option value="property">Property</option>
							<option value="category">Category</option>
							<option value="review">Customer Review</option>
							<option value="banner">Banner</option>
							<option value="video">Video</option>
							</select>
							</div>
							</div>
					</div>

{content.collection_type==='category'?
					<div className="form-group row mb-3">
                           <label className="col-sm-3 col-form-label">Layout Type</label>

					
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Brand Layout 1">Brand Layout 1</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:
					content.collection_type==='video'?


					<div className="form-group row mb-3">
		    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Video Layout 1">Video Layout 1</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>: content.collection_type==='property'?



					<div className="form-group row mb-3">
			    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Product Layout 1">Product Layout 1</option>
							<option value="Product Layout 2">Product Layout 2</option>
							<option value="Product Layout 3">Product Layout 3</option>
							<option value="Product Layout 4">Product Layout 4</option>
							<option value="Product Layout 5">Product Layout 5</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:content.collection_type==='review'?

					<div className="form-group row mb-3">
		    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Customer Review Layout 1">Customer Review Layout 1</option>
							</select>
							<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>:content.collection_type==='banner'?
					<div className="form-group row mb-3">
					    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Layout 1">Layout 1</option>
							<option value="Layout 2">Layout 2</option>
							<option value="Layout 3">Layout 3</option>
							</select>
						<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>
					:
					<div className="form-group row mb-3">
					    <label className="col-sm-3 col-form-label">Layout Type</label>
									
						<div className="col-sm-9 ">
							<div className="input-group">
							<select  name="layout_type" value={content.layout_type} className="form-control" onChange={handleChangeSelect}>
							<option value="">[None]</option>
							<option value="Category Layout 1">Category Layout 1</option>
							</select>
						<span className="text-danger"> *</span>
							</div>
							<span className="error">{errors.layout_type}</span>
							</div>
					</div>}


					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Display Order</label>

				
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange}   name="display_order" value={content.display_order} className='form-control'/>
							
							</div>
							</div>
					</div>



					<div className="form-group row">
                    <label className="col-sm-3 col-form-label">Is Active</label>
			
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.is_active} name="is_active" checked={content.is_active==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>


	</section></div></div></div>
       
            </div>
            
            </div>

         
          
            </div>
            </section>


            <div className="accordion mb-3" id="accordionPicture">
                <div className="accordion-item">
                  <h2 className="accordion-header " id="headingPicture">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePicture" aria-expanded="true" aria-controls="collapsePicture">
                    Properties
                    </button>
                  </h2>
                  <div id="collapsePicture" className="accordion-collapse collapse show" aria-labelledby="headingPicture" data-bs-parent="#accordionPicture" >
                    <div className="accordion-body">
                    <p>
                    You need to save the collection before you can add property for this collection.
					</p>
                    </div>
                  </div>
                </div>
   </div>

</main>
  </Template>
  )
}

export default AddCollection