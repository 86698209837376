import React, { ChangeEvent, FormEvent, useState } from 'react'
import Template from '../components/Template'
import { ImagesUrl, ServerUrl, config } from '../components/Includes';
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify';

import axios from 'axios'
const AddSlider =()=> {
    const [content, setContent] = useState({
		code:Math.random().toString(36).substring(2,9),
		display_order:1,
		isLogo:false, 
		is_active:'true',

    title:'',
    sub_title:'',
    link:'',
    filePath:'', 
    passport:'', 
    imagesFile:''
	})
	
    const [errors, setErrors] = useState({
        image:'',
        imagesFile:'',
        title:''
    });



	const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
		event.preventDefault();
	
		let error = {...errors}; 
		let formIsValid = true;
	
	let msg ='This field is required';
	

	if(!content.imagesFile){
		error.imagesFile=msg;
		formIsValid = false;
	} 

  if(!content.title){
		error.title =msg;
		formIsValid = false;
	} 
	

	if(!formIsValid){
		setErrors(error) 
		}
		
		
		if(formIsValid) {
		
			LoadingModal()
			const fd = new FormData();
			   Object.entries(content).forEach(([key, value]) => {
					   fd.append(key, String(value));
				   });
				  

				   if(content.imagesFile){
					fd.append('imageFile', content.imagesFile)
				   }
				   
				   let url = ServerUrl+'/slider/add';
				   axios.post(url, fd, config)
				   .then(response =>{
					 if(response.data.status === 200){
					  SuccessModal(response.data.message)
					 setContent({
						code:Math.random().toString(36).substring(2,9),
            display_order:1,
            isLogo:false, 
            is_active:'true',

            title:'',
            sub_title:'',
            link:'',
            filePath:'', 
            passport:'', 
            imagesFile:''
					 })


							   } else{
								 ErrorModal('', response.data.message)
							   }   
						   })
						   .catch((error)=>{
							ErrorModal('oops', error.message)
						   }) 
		}

}



    const setLogo =()=>{
	 
      var doc = document.getElementById('photo')
      doc?.click()
      
  setContent({...content, isLogo:false, passport:'',  imagesFile:'', filePath:'', })
}

    
           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	

            if(name==='is_active'){
              value = value==='false'?'true':'false'
            } 
           setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }


         const  handleFile = function(fieldName:any){
          return function(newValue:any){                 
         readURL(newValue);
         }
        } 


        const readURL = (input:any)=>{

          let displayMessage = '';
          
          const PreviewImage = input.target.name;
          const inputElement = document.getElementById('logo') as HTMLInputElement
          const fileName = inputElement.value
          
          var idxDot = fileName.lastIndexOf(".") + 1;
          var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
          
          if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
              //TO DO    
              
               var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
          if (FileSize > 1) {
              displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
              inputElement.value = '';
           
              setContent({...content, filePath:'', imagesFile:''})
          
          } else {
              if (input.target.files && input.target.files[0]) { //Check if input has files.
                  var reader = new FileReader();//Initialize FileReader.
                  reader.onload = function (e:any) {

            

                  //Check if input has files.
                  let Id = Math.random().toString(36).substring(2, 9)
                   let fileUrl = Id+'.png'
          
                   setContent({...content,
                      filePath:fileUrl, 
                      passport:e.target.result, 
          isLogo:true,
                      imagesFile:input.target.files[0]})
                    setErrors({...errors, imagesFile:''}); 
          
                  }
                  reader.readAsDataURL(input.target.files[0]);
                  
              }
          }
          }else{
          displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
          inputElement.value = '';
          setContent({...content, filePath:'', imagesFile:''})
          }   
          
          if(displayMessage.length > 0){
         ErrorModal("", displayMessage)
          }
          }


  return (<Template>

<main id="main" className="main">
<div className="pagetitle">
  <h1>Add Slider</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="/sliders">Slider</a></li>
      <li className="breadcrumb-item active">Add New</li>
    </ol>
<div>
    <button className='btn btn-success ' onClick={handleSubmit}> <i className="bi bi-check2-circle"></i> Save Slider</button>&nbsp;

    <button className='btn btn-warning '  onClick={handleSubmit}> <i className='bi bi-pencil'></i>Save & Edit</button>
    </div>

  </nav>
</div>




<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Add Slider</h5>

<div className="card-body"> 



<div className="row mt-3">
						<section className="card-block">
                        
					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Slider Text</label>
		
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="title" value={content.title} className={errors.title  ? 'form-control form-control-danger ' : 'form-control '}/>
							</div>
							</div>
					</div>
					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Link Url</label>

				
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="link" value={content.link} className='form-control '/>
							</div>
							
							</div>
					</div>

					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Display Order</label>
									
						<div className="col-sm-4 ">
							<div className="input-group">
						<input type="number" onChange={handleChange} name="display_order" value={content.display_order} className='form-control'/>
							
							</div>
							</div>
					</div>



					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Is Active</label>
							
				<div className="col-sm-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={content.is_active} name="is_active" checked={content.is_active==='true'?true:false} onChange={handleChange} />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			</div>
			<span className="error">{errors.image}</span>
	<div className="form-group row mb-3">
    <label className="col-sm-3 col-form-label">Slider Image</label>		
						<div className="col-sm-9 ">
						

						<div className="form-group">
            <img className="img-100 p-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={content.passport}   />
				<label className="btn btn-primary btn-round" htmlFor="logo" > Upload  file </label>
                   
                
        <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round"  > Remove File</button>:''}	
										</div>
							</div>
					</div>

	</section></div>
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>


</main>




  </Template>
  )
}

export default AddSlider