import Cookies from 'js-cookie'
import { decrypt } from './authentication';

export const TINYMCE_KEY ='';
export const ImagesUrl = process.env.REACT_APP_PUBLIC_IMAGE_URL;
export const ServerUrl = process.env.REACT_APP_SERVER_URL;
export const MainUrl = process.env.REACT_APP_MAIN_URL;
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_KEY = process.env.REACT_APP_KEY;


export   const styles = {
    container: (css:any) => ({ ...css, flex: "1 1 auto", alignSelf: "stretch" }),
    menu: (css: any) => ({ ...css, zIndex: 999 }),
    control: (css:any) => ({ ...css,  height:45 })
  };

  export const CURRENCY = '$';

  export const staffCode =Cookies.get('rhrucd')?Cookies.get('rhrucd'):undefined
  export const Token:any = Cookies.get('rhrtk')?Cookies.get('rhrtk'):undefined
  export const GROUP_CODE =  Cookies.get('rhrgpcd')?decrypt(Cookies.get('rhrgpcd')):'null' 


export const config = {
    headers: { 'content-type': 'multipart/form-data',
    'Authorization': `Bearer ${Token}`}
} 