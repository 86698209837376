import React, { useState, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from './Includes';



const TextEditor=(props:any)=> {
    const editorRef = useRef(null as any);
    const [contentEditor, setContentEditor] = useState();

   const handleEditorChange = (content:any, editor:any) => {
     console.log('Content was updated:', content);
    // setContentEditor(content);
   }

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };



  return (
    <>
<Editor
        onInit={(evt, editor) => editorRef.current = editor}
        apiKey={TINYMCE_KEY}
        tinymceScriptSrc={'https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.7.0/tinymce.min.js'} 
  
        init={{
          height: props.height,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview','pagebreak',
            'anchor', 'searchreplace', 'visualblocks', 'visualchars', 'fullscreen', 'emoticons',
            'insertdatetime', 'media', 'table', 'template', 'help', 'wordcount', 'codesample','MathML'
          ],
          toolbar: 'undo redo | blocks | image link media codesample MathML |table|' +
            'bold italic superscript subscript |charmap emoticons | forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat ',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
          
       
        }}
        value={props.description}
        onEditorChange={props.handleEditorChange}
      />


  </>
  )
}

export default TextEditor