import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'

function Logout() {

    let stop:any = Date.now();

    const clearCookies=()=>{
        Cookies.remove('rhrucd')
         Cookies.remove('rhrgpcd')	
      Cookies.remove('rhrtk')
      localStorage.setItem('_expt', stop)
      localStorage.setItem('_bexpt', stop)
    }

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
  
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default Logout