import React from 'react'
import Template from '../components/Template'

const ChangePassword =()=> {
  return (<Template>

<main id="main" className="main">
<div className="pagetitle">
  <h1>Users</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="#!">Users</a></li>
      <li className="breadcrumb-item active">Add New</li>
    </ol>
<div>
    <button className='btn btn-success '> <i className="bi bi-check2-circle"></i> Save User</button>&nbsp;

    <button className='btn btn-warning '> <i className='bi bi-pencil'></i>Save & Edit</button>
    </div>

  </nav>
</div>



<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Update Password</h5>

<div className="card-body"> 


<div className="table">table</div>
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>

</main>
  </Template>
  )
}

export default ChangePassword