import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Template from '../components/Template'
import { ImagesUrl, ServerUrl, config } from '../components/Includes';
import { ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';

import axios from 'axios'
const Setup =()=> {

    const [content, setContent] = useState([] as any)
   
	
    const [errors, setErrors] = useState({
        business_name:'',
        email_address:'',
        telephone:'',
        imagesFile:''
    });



    const fetchContent = async()=>{
        LoadingModal()
            let url = ServerUrl+'/company/view'
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
            setContent(result.data.data)
            
            }
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
             closeModal()
           })
            } 

	const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
		event.preventDefault();
	
		let error = {...errors}; 
		let formIsValid = true;
	
	let msg ='This field is required';
	

	

  if(!content.business_name){
		error.business_name =msg;
		formIsValid = false;
	} 
	
    if(!content.email_address){
		error.email_address =msg;
		formIsValid = false;
	} 



	if(!formIsValid){
		setErrors(error) 
		}
		
		
		if(formIsValid) {
		
			LoadingModal()
			const fd = new FormData();
			   Object.entries(content).forEach(([key, value]) => {
					   fd.append(key, String(value));
				   });
				  

				   if(content.imagesFile){
					fd.append('imageFile', content.imagesFile)
				   }
				   
				   let url = ServerUrl+'/company/update/'+content.code;
				   axios.post(url, fd, config)
				   .then(response =>{
					 if(response.data.status === 200){
					  SuccessModal(response.data.message)
					


							   } else{
								 ErrorModal('', response.data.message)
							   }   
						   })
						   .catch((error)=>{
							ErrorModal('oops', error.message)
						   }) 
		}

}



    const setLogo =()=>{
	 
      var doc = document.getElementById('photo')
      doc?.click()
      
  setContent({...content, isLogo:false, passport:'',  imagesFile:'', filePath:'', })
}


const handleChangeArea =(event:ChangeEvent<HTMLTextAreaElement>)=>{
    let {name, value} = event.target;	

   setContent({...content, [name]:value});
   setErrors({...errors, [name]:''});
 }


    
           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	

            if(name==='is_active'){
              value = value==='false'?'true':'false'
            } 
           setContent({...content, [name]:value});
           setErrors({...errors, [name]:''});
         }


         const  handleFile = function(fieldName:any){
          return function(newValue:any){                 
         readURL(newValue);
         }
        } 


        const readURL = (input:any)=>{

          let displayMessage = '';
          
          const PreviewImage = input.target.name;
          const inputElement = document.getElementById('logo') as HTMLInputElement
          const fileName = inputElement.value
          
          var idxDot = fileName.lastIndexOf(".") + 1;
          var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
          
          if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
              //TO DO    
              
               var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
          if (FileSize > 1) {
              displayMessage = ' File uploaded is larger than maximum size Allow (500 kb).';
              inputElement.value = '';
           
              setContent({...content, filePath:'', imagesFile:''})
          
          } else {
              if (input.target.files && input.target.files[0]) { //Check if input has files.
                  var reader = new FileReader();//Initialize FileReader.
                  reader.onload = function (e:any) {

            

                  //Check if input has files.
                  let Id = Math.random().toString(36).substring(2, 9)
                   let fileUrl = Id+'.png'
          
                   setContent({...content,
                      filePath:fileUrl, 
                      passport:e.target.result, 
          isLogo:true,
                      imagesFile:input.target.files[0]})
                    setErrors({...errors, imagesFile:''}); 
          
                  }
                  reader.readAsDataURL(input.target.files[0]);
                  
              }
          }
          }else{
          displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 500 kb'
          inputElement.value = '';
          setContent({...content, filePath:'', imagesFile:''})
          }   
          
          if(displayMessage.length > 0){
         ErrorModal("", displayMessage)
          }
          }

          useEffect(()=>{
            fetchContent()
          }, [])

  return (<Template>

<main id="main" className="main">
<div className="pagetitle">
  <h1>Company Profile</h1>
  <nav className='d-flex justify-content-between'>
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><a href="#!">Profile</a></li>
    </ol>
<div>
    <button className='btn btn-success ' onClick={handleSubmit}> <i className="bi bi-check2-circle"></i> Update Profile</button>&nbsp;

    </div>

  </nav>
</div>




<section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">
                                    
        <h5 className="card-header">Company Profile</h5>

<div className="card-body"> 



<div className="row mt-3">
						<section className="card-block">
                        
					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Business Name</label>
		
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="business_name" value={content.business_name} className={errors.business_name ? 'form-control form-control-danger ' : 'form-control '}/>
							</div>
							</div>
					</div>
					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Email Address</label>

				
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange} className={errors.email_address ? 'form-control form-control-danger ' : 'form-control '}   name="email_address" value={content.email_address} />
							</div>
							
							</div>
					</div>

					<div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Telephone</label>

				
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="telephone" value={content.telephone} className='form-control '/>
							</div>
							
							</div>
					</div>


                    <div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Office Address</label>

				
									
						<div className="col-sm-9 ">



						 <textarea  className="form-control" rows={5} name="address" 
                    value={content.address} onChange={handleChangeArea} >{content.address}</textarea>
							
							</div>
					</div>



          <div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Website</label>

				
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="website" value={content.website} className='form-control '/>
							</div>
							
							</div>
					</div>


                    <div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Page Title</label>

				
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="page_title" value={content.page_title} className='form-control '/>
							</div>
							
							</div>
					</div>



                    <div className="form-group row mb-3">
                    <label className="col-sm-3 col-form-label">Page Description</label>

				
									
						<div className="col-sm-9 ">
							<div className="input-group">
						<input type="text" onChange={handleChange}   name="page_description" value={content.page_description} className='form-control '/>
							</div>
							
							</div>
					</div>


	<div className="form-group row mb-3">
    <label className="col-sm-3 col-form-label">Logo</label>		
						<div className="col-sm-9 ">
						
                        <div className="form-group">
                        {content.isLogo?
							<img className="img-100 p-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={content.passport}   /> : 
            
							<img className="img-100 p-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/"+content.image_url}   /> }


						
        
				<label className="btn btn-primary btn-round" htmlFor="logo" > Upload  file </label>
                   
                
        <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control display-none" name="logo" type="file"  /> &nbsp;&nbsp;
				{content.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round"  > Remove File</button>:''}	
										</div>
							</div>
					</div>

	</section>
  
  </div>
            
            </div>
            </div>
            
            </div>

         
          
            </div>
            </section>





            <section className="section">
 <div className="row">
<div className="col-lg-12">

				<div className="card ">

        <h5 className="card-header">Email Setup</h5>

						<div className="card-body"> 
					  <div className="form-group row mt-3 mb-3">
                 
                  <label className=" col-sm-3 col-form-label">Sender Name</label>

                          
                    <div className="col-sm-9 ">
                
                    <input type="text" className="form-control form-control-lg" name="sender_name" 
                    value={content.sender_name} onChange={handleChange} />
                      </div>
                  </div>



                  <div className="form-group row mt-3 mb-3">
                 
                 <label className=" col-sm-3 col-form-label">Sender Email</label>

                         
                   <div className="col-sm-9 ">
               
                   <input type="text" className="form-control form-control-lg" name="sender_email" 
                    value={content.sender_email} onChange={handleChange} />
                     </div>
                 </div>



                  <div className="form-group row mb-3">
                  <label className=" col-sm-3 col-form-label">Password</label>
                          
                    <div className="col-sm-9 ">
                    
                    <input type="text" className="form-control form-control-lg" name="password" 
               value={content.password} onChange={handleChange} />
                  </div></div>


                  <div className="form-group row mb-3">
                  <label className=" col-sm-3 col-form-label">Host</label>
                          
                    <div className="col-sm-9 ">
                    
                    <input type="text" className="form-control form-control-lg" name="host" 
               value={content.host} onChange={handleChange} />
                  </div></div>



                  <div className="form-group row mb-3">
                  <label className=" col-sm-3 col-form-label">Port</label>
                          
                    <div className="col-sm-9 ">
                    
                    <input type="text" className="form-control form-control-lg" name="port" 
               value={content.port} onChange={handleChange} />
                  </div></div>


                 



					</div>
	</div></div></div>
  
  </section>

</main>




  </Template>
  )
}

export default Setup